import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Joey King | Software Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Howdy, my name is',
  name: 'Joey King',
  subtitle: 'I am a Software Developer.',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: '1625340263575.jpeg',
  paragraphOne:
    "I'm a software developer fusing creativity and logic. I have grit, an insatiable curiosity, and passion for problem solving.",
  paragraphTwo: `·HTML   ·CSS   ·JavaScript   ·TypeScript   ·Python      ·React.js   ·Express.js   ·Node.js   ·MongoDB`,
  paragraphThree: 'I live in the amazing city of Austin, TX with my wife, kids, and dog.',
  resume: 'https://drive.google.com/file/d/1HSoU_8pHEQurviXYxGpi-7gdCspadiSl/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'CycleScreenShot.png',
    title: 'Cycle',
    info: 'Full-stack community building app where neighbors can exchange goods for free.',
    info2:
      'Tech stack: Django, Django Rest Framework, Celery, Redis, MySQL, React, Axios, Bootstrap',
    url: '',
    repo: '', // if no repo, the button will not show up
    back: 'https://github.com/joeylking/cycle_api',
    front: 'https://github.com/joeylking/cycle',
    youtube: '',
  },
  {
    id: nanoid(),
    img: 'nasascreen.png',
    title: 'NASA Astronomy Picture of the Day',
    info: 'A single page app which displays the NASA APOD using the NASA open API.',
    info2: 'Tech stack: React, React-Router, hooks',
    url: 'https://relaxed-roentgen-391161.netlify.app/',
    repo: 'https://github.com/joeylking/nasa', // if no repo, the button will not show up
    back: '',
    front: '',
    youtube: '',
  },
  {
    id: nanoid(),
    img: 'moviesscreenshot.png',
    title: 'Movies!',
    info: 'A React app that uses the MovieDB API.',
    info2: 'Tech stack: React, hooks, styled components.',
    url: 'https://relaxed-hawking-2ecb28.netlify.app/',
    repo: 'https://github.com/joeylking/Movies', // if no repo, the button will not show up
    back: '',
    front: '',
    youtube: '',
  } /* ,
  {
    id: nanoid(),
    img: '',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    back: '',
    front: '',
    youtube: '',
  },
*/,
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'joey.king@icloud.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/joey-king',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/joeylking',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/joey_king',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
